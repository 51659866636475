import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import {
  Login,
  Root,
  Join,
  Mypage,
  EditInfo,
  Insurance,
  Bill,
  NewPw,
  EditInsurance,
} from "../pages";
import { api } from "../utils/api";
import { SWRConfig } from "swr";
import ReactGA from "react-ga";

ReactGA.initialize("UA-190184919-1");

const App = () => {
  const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    return null;
  };

  const swrConfig = {
    fetcher(url, params) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };

  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <Route path="/" component={logPageView} />
        <Switch>
          <Route exact path="/" component={Root} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/join" component={Join} />
          <Route exact path="/mypage" component={Mypage} />
          <Route exact path="/editInfo" component={EditInfo} />
          <Route exact path="/insurance" component={Insurance} />
          <Route exact path="/edit_insurance" component={EditInsurance} />
          <Route exact path="/bill" component={Bill} />
          <Route exact path="/new_pw" component={NewPw} />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
