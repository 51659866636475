import loadable from "@loadable/component";

export const Root = loadable(() => import("./Root"));
export const Login = loadable(() => import("./Login"));
export const Join = loadable(() => import("./Join"));
export const Mypage = loadable(() => import("./Mypage"));
export const EditInfo = loadable(() => import("./EditInfo"));
export const Insurance = loadable(() => import("./Insurance"));
export const EditInsurance = loadable(() => import("./EditInsurance"));
export const Bill = loadable(() => import("./Bill"));
export const NewPw = loadable(() => import("./NewPw"));
